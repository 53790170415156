<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="返品伝票一覧" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="dummy_supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                :error-messages="alertSupplierMessage"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--返品伝票No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="txt_slipNo"
                maxlength="45"
                :label="$t('label.lbl_returnSlipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateFrom"
                class="txt-single date-style"
                :label="$t('label.lbl_returnDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd, rules.inputRequired]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateFromCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveFrom"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダーの間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- toカレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateTo"
                class="txt-single date-style"
                :label="$t('label.lbl_returnDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd, rules.inputRequired]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <!-- <span>{{ alertMessage }}</span> -->
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateToCal" @input="dateMenu2 = false" />
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="preDateOfAchieveTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDateOfAchieveTo"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!--検索ボタン-->
                <v-btn class="get-btn" @click="getList()">{{ $t("btn.btn_search") }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!-- {{ this.arrivalList[1].freeCostDiv }} -->

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- 返品伝票No -->
          <template v-slot:[`item.returnsNo`]="{ item }">
            <div class="listNo-style">{{ item.returnsNo }}</div>
          </template>

          <!-- 元出荷No -->
          <template v-slot:[`item.outListNo`]="{ item }">
            <div class="listNo-style">{{ item.outListNo }}</div>
          </template>

          <template v-slot:[`item.productNm`]="{ item }">
            <v-btn color="blue darken-1" text @click="openPopup(items)">
              {{ item.productNm }}
            </v-btn>
          </template>

          <!-- 詳細ボタン -->
          <template v-slot:[`item.location`]="{ item }">
            <v-btn small @click="openApproval(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <!-- <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog> -->
      <!-- 進捗情報詳細モーダル-->
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
// import LocationDialog from "../../views/dialogs/Location";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
export default {
  name: appConfig.MENU_ID.P_RTN_SLIP_LIST,
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
    // LocationDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    loadingCounter: 0,
    apiData: [],
    arrivalList: [],
    u_10: false,
    singleSelect: false,
    selected: [],
    pListPopup: false,
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    // 総件数
    totalCount: 0,
    // ロケーションダイアログ
    dialogLocation: false,
    // 入荷予定日付
    arrivalScheduleDateCal: "",
    arrivalScheduleDate: "",
    expireDateCal: "",
    // 荷主伝票番号
    txt_slipNo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",

    inListSid: "", // 修正削除画面に渡すid
    // 取引先名+取引先コード
    suppliersNm: "",
    // 有償/無償
    isPaid: "1",
    // 入荷先
    arrivalSelected: "",
    // 取引先
    suppliersSelected: "",
    // 処理区分
    arrivalStatusSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定No.
    txt_arrivalScheduleNo: "",
    // 入荷実績日付from
    arrAchieveDateFrom: "",
    arrAchieveDateFromCal: "",
    // 入荷実績日付to
    arrAchieveDateTo: "",
    arrAchieveDateToCal: "",
    // 入荷予定日付キャレンダー
    dateMenu: false,
    dateMenu2: false,
    // 取引先エラーメッセージ
    alertSupplierMessage: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    alertMessage: "",
    // 検索時の保持
    searchReturnsNo: "",
    searchArrAchieveDateFrom: "",
    searchArrAchieveDateFromCal: "",
    searchArrAchieveDateTo: "",
    searchArrAchieveDateToCal: "",
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_returnSlipNo"),
        value: "returnsNo",
        width: "7%",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_returnDate"),
        value: "returnsDate",
        width: "7%",
        align: "left",
        sortable: true,
      },
      { text: "出荷先", value: "toName", width: "14%", align: "left", sortable: true },
      {
        text: i18n.tc("label.lbl_OriginalshippingNo"),
        value: "outListNo",
        width: "7%",
        align: "left",
        sortable: true,
      },

      { text: "詳細", value: "location", align: "center", width: "2%", sortable: false },
      // { text: i18n.tc("btn.btn_achieveAdd"), value: "achieveAdd", align: "center", width: "2%" },
    ],
    detailDialogData: {},
    inputList: [],
    dummy_supplierList: [{ text: "データがありません。", value: "" }],
    dummy_arrivalList: [{ text: "", value: "" }],
    dummy_arrivalStatusList: [{ text: "", value: "" }],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productName: "品名",
      productQty: "0",
      arrivalScheduleDate: "0",
      arrivalScheduleNo: "0",
      suppliers: "取引先",
      arrivalto: "入荷先",
      shipNo: "荷主伝票番号",
    },
    defaultItem: {
      arrivalScheduleDate: "0",
      productNm: "",
      productName: "品名",
      productQty: "0",
      rcvDate: "",
      rcvDateJ: "",
      officeSid: "",
      inListNo: "",
      location: "",
      howtoregister: "",
      inStock: "",
      processingdivision: "0",
      arrivalScheduleNo: "0",
      suppliers: "取引先",
      arrivalto: "入荷先",
      shipNo: "荷主伝票番号",
    },
    productList: [
      { text: "", value: "" },
      { text: "新製品", value: "product0" },
      { text: "製品1", value: "product1" },
      { text: "製品2", value: "product2" },
      { text: "製品3", value: "product3" },
      { text: "製品4", value: "product4" },
    ],
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      supplierRequired: (value) => !!value || "取引先を入力してください",
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      // isNumber: (value) =>
      //   !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
      //   i18n.tc("check.chk_inputNumber"),
    },
  }),

  created() {
    this.suppliersSelected = this.$route.params.suppliersNm;
    this.txt_slipNo = this.$route.params.returnsSid;
    this.arrAchieveDateFrom = this.$route.params.arrAchieveDateFrom;
    this.arrAchieveDateFromCal = this.$route.params.arrAchieveDateFromCal;
    this.arrAchieveDateTo = this.$route.params.arrAchieveDateTo;
    this.arrAchieveDateToCal = this.$route.params.arrAchieveDateToCal;
    if (this.suppliersSelected) {
      this.getList();
    } else {
      this.suppliersSelected = "";
    }
  },
  methods: {
    openPopup() {
      this.pListPopup = true;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;

      this.getList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;

      this.getList();
    },

    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      if (this.suppliersSelected) {
        // console.debug("遷移後");
      } else {
        this.arrivalSelected = "";
        this.suppliersSelected = "";
        this.txt_arrivalScheduleNo = "";
        this.arrivalScheduleDate = "";
        this.txt_slipNo = "";
        this.inputList = [];
        this.arrAchieveDateFrom = dateTimeHelper.convertJST();
        this.arrAchieveDateTo = dateTimeHelper.convertJST();

        this.arrivalScheduleDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getSupplier();
      this.getDefinition();
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 入荷実績日付カレンダーFrom
    // 入荷実績日付をー１日
    preDateOfAchieveFrom() {
      if (this.arrAchieveDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateFromCal != toDate) {
        this.arrAchieveDateFromCal = toDate;
      } else {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfAchieveFrom() {
      if (this.arrAchieveDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateFromCal != toDate) {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    // 入荷実績日付カレンダーto
    preDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateToCal != toDate) {
        this.arrAchieveDateToCal = toDate;
      } else {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateToCal != toDate) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 進捗情報詳細画面を開きます。
     */
    openDetailDialog(dummy_input, targetSupplierCode, targetArrivalScheduleNo) {
      // 詳細画面へ渡す値を設定します。
      this.detailDialogData = dummy_input;
      // 選択された列名
      this.detailDialogData.suppliersSelected = targetSupplierCode;
      this.detailDialogData.txt_arrivalScheduleNo = targetArrivalScheduleNo;
      this.isOpenDetailDialog = true;
    },

    // 日付入力
    changeDate() {
      if (this.arrivalScheduleDate.length == 8) {
        var dt = this.arrivalScheduleDate;
        this.arrivalScheduleDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      dateTimeHelper.validDate(this.arrivalScheduleDate);
    },
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateFromCal = null;
      }
    },
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateToCal = null;
      }
    },
    /**
     * 返品伝票承認画面への遷移処理
     */
    openApproval(item) {
      this.alertMessage = "";
      // Fromの日付がtoよりも先だった場合、エラー
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");

        return;
      }
      if (new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else if (
        new Date(this.arrivalScheduleDateFrom).getTime() >
        new Date(this.arrivalScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        // // 選択した行のアイテム
        this.editedIndex = this.inputList.indexOf(item);
        // 取引先名と取引先コードを取得

        for (var i = 0; i < this.dummy_supplierList.length; i++) {
          if (this.dummy_supplierList[i].value == this.searchedSuppliers) {
            this.suppliersNm = this.dummy_supplierList[i].text;
          }
        }

        // 修正画面へ渡す値を設定します。
        this.$router.push({
          name: appConfig.SCREEN_ID.P_RTN_003,
          params: {
            // 検索領域
            // 取引先SID(API登録用)
            suppliersNm: this.searchedSuppliers,
            // 取引先名とコード合体(画面表示用)
            suppliersSelected: this.suppliersNm,
            arrivalSelected: this.arrivalSelected,
            // 返品伝票No
            returnsSid: this.searchReturnsNo,
            // 出荷先
            toName: this.inputList[this.editedIndex].toName,
            toSid: this.inputList[this.editedIndex].toSid,

            // 元集荷No
            outListNo: this.inputList[this.editedIndex].outListNo,
            // 返品伝票No
            returnsNo: this.inputList[this.editedIndex].returnsNo,
            // 返品日from
            arrAchieveDateFrom: this.searchArrAchieveDateFrom,
            arrAchieveDateFromCal: this.searchArrAchieveDateFromCal,
            // 返品日to
            arrAchieveDateTo: this.searchArrAchieveDateTo,
            arrAchieveDateToCal: this.searchArrAchieveDateToCal,
            // 返品日画面表示用
            returnsDateForDisplay: this.inputList[this.editedIndex].returnsDate,
            // 返品日API用
            returnsDateForApi: this.inputList[this.editedIndex].returnsDateForApproval,
            // ボディ領域
            // 品番
            itemCd: this.inputList[this.editedIndex].itemCd,
            // 品名
            itemName: this.inputList[this.editedIndex].itemName,
            // ロット
            lotNo: this.inputList[this.editedIndex].lotNo,
            // 返品理由区分
            reasonDiv: this.inputList[this.editedIndex].reasonDiv,
            // 返品数量
            returnsQuantity: this.inputList[this.editedIndex].returnsQuantity,
            // 備考
            remarks: this.inputList[this.editedIndex].remarks,
            // 出荷伝票明細Sid(承認APIに必要)
            outListSubSid: this.inputList[this.editedIndex].outListSubSid,
            // 出荷伝票Sid(承認APIに必要)
            outListSid: this.inputList[this.editedIndex].outListSid,
            // 責任区分
            blameDiv: this.inputList[this.editedIndex].blameDiv,
            // 出荷品質区分
            shipQualityDiv: this.inputList[this.editedIndex].shipQualityDiv,
            // 出荷品質区分名
            shipQualityDivName: this.inputList[this.editedIndex].shipQualityDivName,
            // 返品品質区分
            returnsQualityDiv: this.inputList[this.editedIndex].returnsQualityDiv,
            // 返品品質区分名
            returnsQualityDivName: this.inputList[this.editedIndex].returnsQualityDivName,
            // 営業所SID
            officeSid: this.officeSid,
          },
        });
      }
    },

    /**
     * 入荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.dummy_supplierList.length; i++) {
        if (this.dummy_supplierList[i].value == this.suppliersSelected) {
          return this.dummy_supplierList[i].name;
        }
      }
    },
    /**
     * 入荷予定登録画面：入荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.dummy_arrivalList.length; i++) {
        if (this.dummy_arrivalList[i].value == this.arrivalSelected) {
          return this.dummy_arrivalList[i].name;
        }
      }
    },
    setStsArrival() {
      for (var i = 0; i < this.dummy_arrivalStatusList.length; i++) {
        if (this.dummy_arrivalStatusList[i].value == this.arrivalStatusSelected) {
          return this.dummy_arrivalStatusList[i].name;
        }
      }
    },
    //入荷作詳細画面ダイアログ閉じるボタン
    onSubmit() {
      this.pListPopup = false;
    },
    // 動作確認用dummyデータ
    getDummy() {
      if (
        // FromToの日付、入力チェック
        new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_fromToDate");
      } else {
        var dummyJsonData = [
          {
            shipNo: "testShipNo",
            arrivalScheduleDate: "2022/05/23",
            arrivalto: "出荷先A",
            originalShippingNo: "0000-0000",
            location: "",
          },
        ];
        const dummyJson = JSON.parse(JSON.stringify(dummyJsonData));
        const list = [];
        // 出荷予定リストを取得
        dummyJson.forEach((row) => {
          list.push({
            // 返品伝票No
            shipNo: row.shipNo,
            //返品日
            arrivalScheduleDate: row.arrivalScheduleDate,
            // 出荷先
            arrivalto: row.arrivalto,
            // 元出荷No
            originalShippingNo: row.originalShippingNo,
          });
        });
        this.inputList = list;
      }
    },
    //検索ボタン押下時
    getList() {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      if (this.$route.params.suppliersNm) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      if (inputCheckResult) {
        this.alertMessage = "";
        // Fromの日付がtoよりも先だった場合、エラー
        if (this.suppliersSelected == "" || this.suppliersSelected == null) {
          return;
        }
        if (
          new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo).getTime()
        ) {
          this.alertMessage = i18n.tc("check.chk_inputFromVali");
        } else if (
          new Date(this.arrivalScheduleDateFrom).getTime() >
          new Date(this.arrivalScheduleDateTo).getTime()
        ) {
          this.alertMessage = i18n.tc("check.chk_inputFromVali");
        } else {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          this.$set(this.inputList, "", "");
          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();

          config.params.reqComPaginationFlg = "1"; //ページングする場合は1;

          config.params.returnsFrom = dateTimeHelper.convertUTC(this.arrAchieveDateFrom);

          var arr = this.arrAchieveDateTo + " 23:59:59.999";
          config.params.returnsTo = dateTimeHelper.convertUTC(arr);

          if (this.txt_slipNo) {
            //返品伝票No
            config.params.returnsNo = this.txt_slipNo;
          }
          config.params.clientSid = this.suppliersSelected;
          config.params.officeSid = sessionStorage.getItem("sales_office_sid"); //2200000002の固定
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;
          // ソート
          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }
          // console.debug("getList() Config", config);
          // 接続先のAPI_URLを入力
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              // APIの情報を入力する
              .secureGet(appConfig.API_URL.BIZ_RETURNS, config)
              .then((response) => {
                this.inputList.splice(0);

                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getList() Response", response);
                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  // alert("API接続に成功しました。");
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  this.returnList = jsonData.resIdv.returns;
                  // for(){}繰り返し処理を行い、配列を格納する処理を記述する
                  for (let i = 0; i < this.returnList.length; i++) {
                    let data = {
                      returnsNo: this.returnList[i].returnsNo, // 返品伝票No
                      arrivalScheduleDate: dateTimeHelper
                        .convertUTC2JST(this.returnList[i].returnsDate)
                        .substr(0, 10), // 返品日
                      toName: this.returnList[i].toName, //出荷先
                      toSid: this.returnList[i].toSid,
                      outListNo: this.returnList[i].outListNo, //元出荷No
                      returnsDate: dateTimeHelper
                        .convertUTC2JST(this.returnList[i].returnsDate)
                        .substr(0, 10), //返品日
                      returnsDateForApproval: this.returnList[i].returnsDate, //承認に渡す値
                      itemCd: this.returnList[i].itemCd, //品番
                      itemName: this.returnList[i].itemName, //品名
                      lotNo: this.returnList[i].lotNo, //ロット
                      reasonDiv: this.returnList[i].reasonDiv, //返品理由区分
                      returnsQuantity: this.returnList[i].returnsQuantity, //返品数量
                      remarks: this.returnList[i].remarks, //備考
                      outListSubSid: this.returnList[i].outListSubSid, //出荷伝票明細Sid(承認APIに必要)
                      outListSid: this.returnList[i].outListSid, // 出荷伝票Sid(承認APIに必要)
                      blameDiv: this.returnList[i].blameDiv, // 責任区分
                      shipQualityDiv: this.returnList[i].shipQualityDiv, // 出荷品質区分
                      shipQualityDivName: this.returnList[i].shipQualityDivName, // 出荷品質区分名
                      returnsQualityDiv: this.returnList[i].returnsQualityDiv, // 返品品質区分
                      returnsQualityDivName: this.returnList[i].returnsQualityDivName, // 返品品質区分名

                      // processingdivision: this.returnList[i].qualityDiv, //処理区分
                      // processDivName: this.returnList[i].processDivName, //処理区分名
                      // qualityDivName: this.returnList[i].qualityDivName,
                      // reasonCdName: this.returnList[i].reasonCdName,
                      // fromSid: this.returnList[i].fromSid, //入荷元SId
                      // howtoregister: this.returnList[i].entryDiv,
                      // location: this.returnList[i].inListSid, //入荷予定明細Sid
                      // suppliersSelected: this.suppliersSelected,
                      // inListNo: this.returnList[i].inListNo, //入荷No
                      // rcvDate: dateTimeHelper.convertUTC2JST(this.returnList[i].inScheduleDate), //入荷予定日
                      // arrivalSelected: this.arrivalSelected,
                      // officeSid: this.returnList[i].officeSid,
                      // clientSid: this.returnList[i].clientSid,
                    };
                    this.inputList.push(data);
                  }
                  // エラー時
                } else {
                  // 複数業務エラーが発生しうるのであればrejectすること。
                  this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);
                  // エラーメッセージをpopupのmessageに格納
                  this.infoDialog.message = jsonData.resCom.resComMessageId
                    ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                    : jsonData.resCom.resComMessages[
                        "resComMessage" + sessionStorage.getItem("lang")
                      ];
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;
                }
                resolve(response);
                // 検索値保持
                this.searchedSuppliers = this.suppliersSelected; // 取引先SID
                this.officeSid = config.params.officeSid; //営業所SID
                this.searchReturnsNo = this.txt_slipNo; //返品伝票No
                this.searchArrAchieveDateFrom = this.arrAchieveDateFrom; //返品日from
                this.searchArrAchieveDateFromCal = this.arrAchieveDateFromCal; //返品日fromカレンダー
                this.searchArrAchieveDateTo = this.arrAchieveDateTo; //返品日to
                this.searchArrAchieveDateToCal = this.arrAchieveDateToCal; //返品日toカレンダー
              })
              .catch((resolve) => {
                console.error("getList() Resolve", resolve);
                this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    // 処理区分API接続
    getDefinition() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const processDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);
      Promise.all([processDivList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.dummy_arrivalStatusList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 取引先API接続
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                });
              });
              this.dummy_supplierList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal(val) {
      this.arrivalScheduleDate = this.formatDate(val);
    },
    arrAchieveDateFromCal(val) {
      this.arrAchieveDateFrom = this.formatDate(val);
    },
    arrAchieveDateToCal(val) {
      this.arrAchieveDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getList();
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_RTN_002_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
// #listData {
//   &.v-data-table--fixed-header ::v-deep {
//     //テーブルデータ部分
//     td {
//       &:nth-child(7) {
//         color: red;
//         font-weight: 900;
//       }
//     }
//   }
// }
</style>
